import React, { useEffect, useState } from 'react'
import { useMainContext } from '../context/mainContext';
import { useInView } from 'react-intersection-observer';

const FifthPage = () => {
    const { state: { submitting, t }, handleContactMessage, handlePageChange, handleToTopShow, handleLoading } = useMainContext();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    // useInView hook to detect if the icons are in view
    const { ref, inView } = useInView({
        triggerOnce: false, // Only trigger the animation once
        threshold: 0.2, // The percentage of the icon visible in the viewport to trigger the animation
    });

    useEffect(() => {
        if(inView) {
          handlePageChange("contact");
          handleToTopShow(true);
        }
    }, [inView]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleLoading('submit');
        formData.name = formData.firstName + " " + formData.lastName;
        handleContactMessage(formData);
        // Clear the form by resetting the state
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            message: '',
        });
        
    };

    return (
        <div ref={ref} id="contact" className='md:px-10 px-5 pt-16 pb-9 md:pt-16 text-black dark:text-white dark:bg-gray-800 selection:bg-primary dark:selection:bg-thirdary dark:selection:text-black min-h-screen'>
            <h2 className='selection:text-black text-primary drop-shadow-customlg border-s-[2px] border-e-thirdary border-s-secondary ps-5 text-3xl md:text-4xl capitalize'>{t('contact.title')}</h2> 
            <div className='grid gap-9 grid-cols-1 min-[1260px]:grid-cols-7 md:grid-cols-6 h-full mt-10'>
                <div className='col-span-3 flex flex-col justify-start'>
                    <p className='text-base md:text-lg font-sans mt-3 md:mt-5'>{t('contact.firstDesc')}</p>
                    <p className='font-sans text-base md:text-lg mt-6'>{t('contact.secondDesc')}</p>
                    <p className='font-sans text-base md:text-lg mt-6'>{t('contact.thirdDesc')}</p>
                </div>
                <div className='col-span-3'>
                    <form className='flex flex-col text-sm' onSubmit={handleFormSubmit}>
                        <div className='flex gap-4 items-center flex-wrap'>
                            <input type="text" autoComplete='off' onChange={handleChange} value={formData.firstName} name='firstName' className="dark:bg-transparent py-3 px-0 focus:outline-none border-x-0 border-t-0 focus:ring-0 placeholder:font-serif flex-1 text-sm md:text-base" required placeholder={`${t('contact.label1')} *`}/>
                            <input type="text" autoComplete='off' onChange={handleChange} value={formData.lastName} name='lastName' className="dark:bg-transparent py-3 px-0 focus:outline-none border-x-0 border-t-0 focus:ring-0 placeholder:font-serif flex-1 text-sm md:text-base" placeholder={`${t('contact.label2')}`}/>
                        </div>
                        <input type="email" autoComplete='off' onChange={handleChange} value={formData.email} name='email' className="mt-7 dark:bg-transparent py-3 px-0 focus:outline-none border-x-0 border-t-0 focus:ring-0 placeholder:font-serif flex-1 text-sm md:text-base" required placeholder={`${t('contact.label3')} *`}/>
                        <label className="mt-7">
                            <span className='block text-gray-500 selection:text-black'>{t('contact.label4')} *</span>
                            <textarea autoComplete='off' required onChange={handleChange} value={formData.message} name='message' className='w-full mt-4 dark:bg-transparent resize-none' rows={5} maxLength={500}></textarea>
                        </label>
                        
                        <button className="button-30 self-start px-5 py-2 md:py-3 mt-5 bg-primary flex items-center gap-2 dark:bg-thirdary font-serif dark:text-black md:text-base text-sm" role="button">
                            <span>{t('contact.btn')}</span>
                            { submitting &&
                                <svg class="animate-spin h-5 w-5 text-black" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.87 3.188 8.062l2-2.666z"></path>
                                </svg> 
                            }
                        </button>
                    </form>
                </div>
                <div className='col-span-1'></div>
            </div>
        </div>
    );
};

export default FifthPage