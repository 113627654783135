import React, { useState } from 'react'
import { FiFacebook, FiGithub, FiInstagram, FiLinkedin, FiTwitter } from 'react-icons/fi';
import { FaCodepen } from 'react-icons/fa';
import { useMainContext } from '../context/mainContext';

const Footer = () => {
    const { state: { sending, t }, handleContactMessage, handleLoading } = useMainContext();
    const [ email, setEmail ] = useState('');


    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleLoading('send');
        const body = {
            name: "Email User",
            email: email,
            message: 'Reaching out with gmail :)'
        }
        handleContactMessage(body);
        // Clear the form by resetting the state
        setEmail('');
    };

    return (
        <div className='text-black dark:text-white bg-gray-200 dark:bg-gray-900 px-5 pt-10 md:p-10 md:pb-0 overflow-y-hidden'>
            <div className='grid grid-cols-2 md:grid-cols-6 md:gap-0 gap-6 overflow-y-hidden'>
                <div className='col-span-2 flex flex-col justify-center gap-3'>
                    <span className='text-black dark:text-thirdary font-bold'>Hein Htet</span>
                    <p className='text-sm font-sans'>{t('footer.desc')}</p>
                </div>
                <form onSubmit={handleFormSubmit} className='col-span-2 flex items-center md:justify-center'>
                    <input required value={email} autoComplete='off' onChange={handleChange} name='email' type='email' className='px-2 py-2 border-none w-full md:w-3/4 text-sm focus:outline-none focus:ring-0 dark:text-black' placeholder={t('footer.emailPlaceholder')} />
                    <button type='submit' className='bg-primary dark:text-black px-3 py-2 text-sm flex items-center gap-2'>
                        <span>{t('footer.btn')}</span>
                        { sending &&
                            <svg class="animate-spin h-5 w-5 text-black" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.87 3.188 8.062l2-2.666z"></path>
                            </svg> 
                        }
                        
                    </button>
                </form>
                <div className='col-span-2 flex justify-between sm:justify-around mr-0 min-[1260px]:mr-4'>
                    <div>
                        <span className='text-sm font-bold'>{t('footer.followMe')}</span>
                        <div className='flex items-center gap-4 mt-4'>
                            <a href='https://www.facebook.com/profile.php?id=100095516521912' target='_blank' title={t('myFb.title')} >
                                <FiFacebook className='text-xl' />
                            </a>
                            <a href='https://instagram.com/hein_htet.arjun?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D' target='_blank' title={t('myinsta.title')}>
                                <FiInstagram className='text-xl' />
                            </a>
                            <a href='https://www.facebook.com/profile.php?id=100095516521912' target='_blank' title={t('mytwitter.title')}>
                                <FiTwitter className='text-xl' />
                            </a>
                            <a href='https://www.linkedin.com/in/hein-82-htet' target='_blank' title={t('mylinkedIn.title')}>
                                <FiLinkedin className='text-xl' />
                            </a>
                        </div>
                    </div>
                    <div>
                        <span className='font-bold text-sm'>{t('menu.projects')}</span>
                        <div className='flex items-center gap-4 mt-4'>
                            <a href='https://github.com/JamesCartar' target='_blank' title={t('myGithub.title')}>
                                <FiGithub className='text-xl' />
                            </a>
                            <a href='https://codepen.io/jamescartar' target='_blank' title={t('myCodepen.title')}>
                                <FaCodepen className='text-xl' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center mt-14'>
                <span className=' md:border-t-[1px] border-gray-400 dark:border-gray-800 pt-2 pb-1 px-5 text-[12px] md:text-sm'>{t('footer.copyright')}  &#169; 2023. <span className='hidden sm:inline'>Hein Htet.</span> {t('footer.text')}</span>
            </div>
        </div>
    )
}

export default Footer;