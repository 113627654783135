import { useEffect, useState } from 'react';
import Email from './component/email.js';
import FirstPage from './component/firstPage.js';
import Loading from './component/loading.js';
import Navbar from './component/navbar.js';
import SecondPage from './component/secondPage.js';
import { useMainContext } from "./context/mainContext.js";
import ThirdPage from './component/thirdPage.js';
import FourthPage from './component/fourthPage.js';
import FifthPage from './component/fifthPage.js';

import successRating from './img/successRating.gif';
import Footer from './component/footer.js';
import ToTop from './component/toTop.js';

import { SlEmotsmile } from 'react-icons/sl';

function App() {
  const { state: { darkTheme, ratingMessage } }  = useMainContext();
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [isLoading]);  

  return (
    <div className={`${ darkTheme ? "dark" : '' } ${isLoading && "overflow-y-hidden"} overflow-x-hidden relative scroll-smooth`}>
      {
        isLoading ? <Loading /> : 
        <>
          <Navbar />
          {ratingMessage && <div className='px-3 shadow-lg fixed right-3 top-20 flex justify-start items-center gap-2 bg-gray-600 text-white z-[60] py-1'>
            <span>🤩</span>
            <span className='font-serif'> {ratingMessage}</span>
          </div>}
          <ToTop />
          <Email />
          <FirstPage />
          <SecondPage />
          <ThirdPage />
          <FourthPage />
          <FifthPage />
          <Footer />
        </>
      }
    </div>
  );
}

export default App;
